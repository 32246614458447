.pqab_formitem_box{
  width: 100%;
}
.pqab_formitem_box .ant-select-selector{
  border: 1px solid rgba(0,0,0,0.15) !important;
  border-radius: 5px!important;
}
.pqab_searchBtn{
  font-size: 1.2rem;
  float: right;
  color:  #666666;
}
.pqab_searchBtn span{
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
}
.pqab_searchBtn:hover{
  color:#5452a4;
}
.pqab_searchBtn:focus{
  color:#5452a4;
}
