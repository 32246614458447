.menu_sider{
  overflow: auto;
  height: 100vh;
  position:fixed;
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  box-shadow: 0px 9px 28px 0px rgba(83,84,159,0.20);
}
.menu_logo{
  padding:2rem 1rem 2rem 1rem;
  box-shadow: 2px 0px 20px 0px rgba(84,82,164,0.16);
}
.menu_miniogo{
  padding:1rem 1rem 1rem 1rem;
  box-shadow: 2px 0px 20px 0px rgba(84,82,164,0.16);
}
.menu_logo_img{
  width: 80%;
  margin: 0 auto;
  display: block;
}
.menu_menu{
  width: 85%;
  margin: 1rem auto;
  padding:6px;
}
.menu_menu .ant-menu-item{
  height: 50px;
  line-height: 50px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}
.ant-menu-item a{
  color: #5452a4;
}
.ant-menu-item-selected a{
  color: #ffffff;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: #ffffff;
}
.ant-menu{
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #5452a4;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5452a4;
  border-radius: 10px;
  box-shadow: 4px 0px 28px 0px rgba(84,82,164,0.10);
  color: #ffffff;
}
.menu_submenu .ant-menu-submenu-title .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
  color:rgba(0,0,0,0.25);
}
.ant-menu-sub.ant-menu-inline {
  background: #ffffff;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: inherit;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  color: #dddff8;
}
.ant-menu-submenu-title:hover{
  color:#dddff8;
}
.ant-menu-item:hover{
  color:#dddff8;
}
.ant-menu-item a:hover, .ant-menu-item-active{
  color:#dddff8;
}
.ant-menu-inline{
  border-right:inherit;
}
.ant-menu-sub{
  font-size: 12px;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: inherit;
}
.ant-menu-submenu-selected{
  color: #ffffff;
  background-color: #5452a4;
  border-radius: 10px;
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow{
  color:#ffffff;
}
.menu_user_btn{
  background: #5452a4;
  border-radius: 3px;
}
.menu_user_menu{
  width: 85%;
  margin: 1rem auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.menu_user_menu .ant-menu-item{
  height: 40px;
  line-height: 40px;
}
.menu_menu_item{
  overflow: auto;
  height: 78vh;
  position:fixed;
}
.menu_badge{
  position: absolute;
  top:30%;
  right: 16px;
}
.baike_list_card{
  z-index: 10;
  left: 66px;
  top: 300px;
  position:absolute;
  background: #ffffff;
  border-radius: 4px;
  border-color: #ffffff;
  box-shadow: 4px 0px 28px 0px rgba(84,82,164,0.10);
}
