body{
  background: #fcfcff!important;
}
.menu_inout_btn{
  color:rgba(0,0,0,0.25);
  position:fixed;
  top:50%;
  height:8%;
  border:0px;
  background-color: #ffffff;
  box-shadow: 0px 9px 28px 0px rgba(83,84,159,0.20);
}
.menu_inout_btn:hover{
  color: #5452a4;
}
.site-layout{
  background-color: #fcfcff!important;
}
.baike_list_text{
  font-size: 1rem;
  font-family: PingFangSC-Medium;
  text-align: left;
  color: rgba(0,0,0,0.85);
}
.baike_list_card .ant-menu-submenu .ant-menu-submenu-title{
  padding-left: 0px!important;
}
.baike_list_card .ant-menu-inline .ant-menu-submenu-title{
  padding-right: 80px;
}
.baike_list_card .ant-menu-submenu ul li.ant-menu-item.ant-menu-item-only-child{
  padding-left: 24px!important;
}
.baike_list_card .ant-menu-submenu-selected{
  border-radius:initial;
  background-color: #ffffff;
  color:#1890ff;
}
.baike_list_card .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}

.baike_list_card .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: #1890ff;
}
.baike_list_card  .menu_submenu .ant-menu-submenu-title .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
  color: rgba(0, 0, 0, 0.25) !important;
}

@media screen and (max-width: 576px) {
  .mobile_header{
    background-color: #ffffff;
    box-shadow: 0px 9px 28px 0px rgba(83,84,159,0.20);
  }
  .mobile_menu_logo_img{
    padding:10px;
    width: 50%;
    display: block;
    margin: 0 auto;
  }
  .mobile_menu_btn_icon{
    font-size:22px!important;
    color:#666666;
    margin-top: 5px;
  }
  .ant-drawer-open.mobile_menu_drawer{
    margin-top: 65px;
  }
  .ant-drawer-open.mobile_menu_drawer .ant-drawer-content-wrapper{
    height: 70% !important;
    border-radius: 0px 0 12px 12px;
  }
  .ant-drawer-open.mobile_menu_drawer .ant-drawer-body{
    padding: 0;
  }
  .ant-drawer-open.mobile_menu_drawer .ant-drawer-content{
    border-radius: 0px 0 12px 12px;
  }
  .footer_text{
    font-size: 0.7rem;
  }
}
